import { useState } from "react";
import { Grid } from "@mui/material";
import { TextField, Button, Paper, Avatar, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { User } from ".";
import { LoginSchema } from "./login.schema";
import { login } from "services";
import axios from "axios"; // Import the axios library
import { getExchangeInfo } from "components/BinanceFuture/Order/order.service"; //"components/BinanceFuture/Order/order.service";

interface SymbolInfo {
  symbol: string;
  quantityPrecision: Float32Array;
}
const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedPage, setSelectedPage] = useState(""); // Added state variable
  const { register, handleSubmit, formState } = useForm<User>({
    mode: "onBlur",
    resolver: yupResolver(LoginSchema),
  });
  const [symbolsAll, setSymbolsAll] = useState<SymbolInfo[]>([]);

  const { errors } = formState;

  const onChangeUsername = (e: any) => {
    setUsername(e.target.value);
  };

  const onChangePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    try {
      await login(username, password, navigate).then((response: any) => {
        setSelectedPage(""); // Reset selected page
        if (response) {
          // Show the target page options only on successful login
          setSelectedPage(response);
        }
      });
    } catch (error: any) {
      alert(error);
    }
  };

  const handlePageSelection = async (page: string) => {
    if (page === "BinanceFuture") {
      //   const response = await axios.get(
      //     "https://fapi.binance.com/fapi/v1/exchangeInfo"
      //   );
      console.log("PP");
      const response = await getExchangeInfo("binance");
      const usdtSymbols = response.data; // Adjust this based on your server response structure
      console.log("response.data", response.data);
      setSymbolsAll(usdtSymbols);
      navigate("/BinanceFuture", { state: { symbolsAll: usdtSymbols } });
    } else if (page === "KucoinFuture") {
      // navigate("/KucoinFuture");
    }
  };

  const paperStyle = {
    padding: 20,
    height: "70vh",
    width: 280,
    margin: "20px auto",
  };

  const avatarStyle = {
    background: "green",
  };

  return (
    <Grid>
      <Paper elevation={10} style={paperStyle}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>
          <h2>Login</h2>
        </Grid>
        <Box
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
        >
          <form onSubmit={onSubmit}>
            <TextField
              {...register("username", { required: true })}
              fullWidth
              label="Username"
              placeholder="Type your username"
              variant="filled"
              value={username}
              helperText={errors.username && errors.username.message}
              error={!!errors.username}
              onChange={onChangeUsername}
            />
            <TextField
              {...register("password", { required: true })}
              fullWidth
              type="password"
              label="Password"
              placeholder="Type your password"
              variant="filled"
              value={password}
              helperText={errors.password && errors.password.message}
              error={!!errors.password}
              onChange={onChangePassword}
            />
            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            {selectedPage && (
              <div style={{ marginTop: 20 }}>
                <p>Select Broker Page:</p>
                <Button
                  variant="outlined"
                  onClick={() => handlePageSelection("BinanceFuture")}
                >
                  Binance Page
                </Button>
                <Button
                  variant="outlined"
                  // onClick={() => handlePageSelection("KucoinFuture")}
                >
                  KuCoin Page
                </Button>
              </div>
            )}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              // disabled={loading || !Object.keys(errors).length === 0}
              className="btn btn-primary"
            >
              {formState.isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Login
            </Button>
          </form>
        </Box>
      </Paper>
    </Grid>
  );
};

export default Login;
