import React, { useEffect, useState } from "react";
import { VariableSizeList } from "react-window";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { getPivotApi } from "components/BinanceFuture/Order/order.service";
import { FormControlLabel,Switch } from "@mui/material";

dayjs.extend(utc);

type YourDataItemType = {
  _id: string;
  symbol: string;
  low: number;
  lowTime: Date;
  status: string;
  createTime: Date;
  touchDate: Date;
  high: number;
  highTime: Date;
};

type PropType = {
  description: string;
  setIndexProp: React.Dispatch<React.SetStateAction<number>>;
  setSymbol: React.Dispatch<React.SetStateAction<string>>;
  price: string;
  setPrice: React.Dispatch<React.SetStateAction<string>>;
  sl: string;
  setSl: React.Dispatch<React.SetStateAction<string>>;
  tp1: string;
  setTp1: React.Dispatch<React.SetStateAction<string>>;
  tp2: string;
  setTp2: React.Dispatch<React.SetStateAction<string>>;
  setLisener: React.Dispatch<React.SetStateAction<boolean>>;
  lisener: boolean;
  // setFotprint: React.Dispatch<React.SetStateAction<boolean>>;
  // fotprint: boolean;
};
const PivotWidget: React.FC <PropType>= (props) => {
  const [data, setData] = useState<YourDataItemType[]>([]);
  const [tableHeaders, setTableHeaders] = useState<(keyof YourDataItemType)[]>([]);
  const [changeSw, setChangeSw] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = (await getPivotApi(props.description,"BTCUSDT",changeSw));
        console.log("result",result) 
        // return 0// Adjust the API call based on your actual implementation
        setData(result);

        // Extracting unique keys from the first data object for table headers
        if (result.length > 0) {
          const keys = Object.keys(result[0]) as (keyof YourDataItemType)[];
          setTableHeaders(keys);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [changeSw]);

  const getRowHeight = (index: number) => {
    return 40;
  };
const controllSw=()=>{
  setChangeSw(!changeSw)
}
  const Row: React.FC<{ index: number; style: React.CSSProperties }> = ({ index, style }) => (
    <TableRow style={style}>
      {tableHeaders.map((header) => (
        <TableCell key={header as string}>
         
          { data[index][header]}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <div>
      <FormControlLabel
          control={
            <Switch
              // checked={autoRefresh}
              onChange={controllSw}
              name="NEWvirgin"
              //   trackColor={{ false: 'red', true: 'green' }}
              inputProps={{ "aria-label": "Toggle For Get New Virgins" }}
            />
          }
          label={" NEWvirgin"}
        />
      
    <Table      
    width={800}>
      <TableHead>
        <TableRow>
          {tableHeaders.map((header) => (
            <TableCell key={header as string}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      </Table>
      <Table>
      <TableBody>
        <VariableSizeList
              height={400}
              width={800}
          itemCount={data.length}
          itemSize={getRowHeight}
        >
          {Row}
        </VariableSizeList>
      </TableBody>
    </Table>
    </div>
  );
};

export default PivotWidget;
