import axios from 'axios';
import authHeader from './ auth_header';

const API_URL = 'https://ncanuks.site/api/test/';
// const API_URL = 'http://54.171.3.112:3001/api/test/';
// const API_URL = 'http://knocknock.autos/api'; //production address

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}

export default new UserService();