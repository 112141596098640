import React, { useEffect, useState, useCallback, useRef } from "react";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { CSVLink } from "react-csv";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { getFootPrintApi } from "components/BinanceFuture/Order/order.service";
const addThousandSeparator = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
type YourItemType = {
  _id: string;
  symbol: string;
  closeTime: Date;
  totalVolume: number;
  delta: number;
  open: number;
  close: number;
  high: number;
  low: number;
  POC: number;
};

type RowData = {
  _id: string;
  symbol: string;
  closeTime: Date;
  totalVolume: number;
  delta: number;
  open: number;
  close: number;
  high: number;
  low: number;
  POC: number;
};
type RowProps = {
  index: number;
  style: React.CSSProperties;
  data: RowData[];
  midVolume: number;
  avgVolume: number;
};

const Row: React.FC<RowProps> = ({
  index,
  style,
  data,
  midVolume,
  avgVolume,
}) => {
  const rowData = data[index];

  const rowStyles: React.CSSProperties = {
    ...style,
    display: "flex",
    backgroundColor:
      rowData.totalVolume > midVolume
        ? "#edeec0"
        : rowData.totalVolume > avgVolume
        ? "#caf0f8"
        : "transparent",
    alignItems: "center", // Center vertically
    justifyContent: "center", // Center horizontally
  };
  const leftCellStyles: React.CSSProperties = {
    // textAlign: 'left',
    alignItems: "left", // Center vertically
    // justifyContent: 'left',  // Center horizontally
  };
  const deltaCellStyles: React.CSSProperties = {
    color: rowData.delta < 0 ? "red" : "black", // Set red background if delta is negative
  };
  const barColor: React.CSSProperties = {
    color: rowData.open <= rowData.close ? "blue" : "#DC582A", // Set red background if delta is negative
  };
  return (
    <TableRow style={rowStyles} key={rowData._id}>
      <TableCell style={leftCellStyles}>
        {rowData.closeTime.toString().slice(5, -5).replace(/T/g, " ")}
      </TableCell>
      <TableCell>
        {addThousandSeparator(rowData.totalVolume.toFixed(0))}
      </TableCell>
      <TableCell style={deltaCellStyles}>
        {addThousandSeparator(rowData.delta.toFixed(0))}
      </TableCell>
      <TableCell>{rowData.open}</TableCell>
      <TableCell style={barColor} >{rowData.close}</TableCell>
      <TableCell>{rowData.high}</TableCell>
      <TableCell>{rowData.low}</TableCell>
      <TableCell>{rowData.POC}</TableCell>
    </TableRow>
  );
};

type PropType = {
  description: string;
  setIndexProp: React.Dispatch<React.SetStateAction<number>>;
  setSymbol: React.Dispatch<React.SetStateAction<string>>;
  price: string;
  setPrice: React.Dispatch<React.SetStateAction<string>>;
  sl: string;
  setSl: React.Dispatch<React.SetStateAction<string>>;
  tp1: string;
  setTp1: React.Dispatch<React.SetStateAction<string>>;
  tp2: string;
  setTp2: React.Dispatch<React.SetStateAction<string>>;
  setLisener: React.Dispatch<React.SetStateAction<boolean>>;
  lisener: boolean;
  setFotprint: React.Dispatch<React.SetStateAction<boolean>>;
  fotprint: boolean;
};

const FootprintWidget: React.FC<PropType> = (props) => {
  const [data, setData] = useState<RowData[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [maxVolume, setMaxVolume] = useState(0);
  const [midVolume, setMidVolume] = useState(0);
  const [avgVolume, setAvgVolume] = useState(0);
  const [autoRefresh, setAutoRefresh] = useState(false);

  let dataSize = useRef<number>(0);
  let last = useRef<string>("empty");
  let first = useRef<string>("");

  // const [after,setAfter]=useState('')

  const fetchData = useCallback(async () => {
    try {
      setIsFetching(true);

      console.log("data ", dataSize.current);
      console.log("last ", last.current);

      // Make the API call
      const response = await getFootPrintApi(
        props.description,
        "old",
        last.current,
        "BTCUSDT"
      );

      // Check if there is a comment in the response
      if (response.comment !== undefined) {
        console.log(response.comment);
        setMaxVolume(response.comment.maxTotalVolume);
        setMidVolume(response.comment.maxTotalVolume / 2);
        setAvgVolume(response.comment.avgTotalVolume);
      }

      // Check if there is an array of results in the response
      if (Array.isArray(response.result)) {
        // Map the result items to the desired format and update the state
        const newData = response.result.map((item: YourItemType) => ({
          _id: item._id,
          symbol: item.symbol,
          closeTime: item.closeTime,
          totalVolume: item.totalVolume,
          delta: item.delta,
          open: item.open,
          close: item.close,
          high: item.high,
          low: item.low,
          POC: item.POC,
        }));

        // Use the state updater function to ensure you get the latest state
        setData((prevData) => [...prevData, ...newData]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsFetching(false);
    }
  }, [props.description]);

  useEffect(() => {
    const getNewData = async () => {
      try {
        // Make the API call for new data

        const response = await getFootPrintApi(
          props.description,
          "new",
          first.current,
          "BTCUSDT"
        );

        // Process the new data and add it to the top of the table
        if (Array.isArray(response.result)) {
          const newData = response.result.map((item: YourItemType) => ({
            _id: item._id,
            symbol: item.symbol,
            closeTime: item.closeTime,
            totalVolume: item.totalVolume,
            delta: item.delta,
            open: item.open,
            close: item.close,
            high: item.high,
            low: item.low,
            POC: item.POC,
          }));

          const uniqueData = new Set([...newData, ...data]);

          // Convert Set back to an array and update the state
          setData(Array.from(uniqueData));
          //   setData((prevData) => [...newData, ...prevData]);
          if (response.comment !== undefined) {
            console.log(response.comment);
            setMaxVolume(response.comment.maxTotalVolume);
            setMidVolume(response.comment.maxTotalVolume / 2);
            setAvgVolume(response.comment.avgTotalVolume);
          }
        }
      } catch (error) {
        console.error("Error fetching new data:", error);
      }
    };

    const interval = setInterval(() => {
      if (autoRefresh) {
        getNewData();
      }
    }, 60000); // Run every minute (adjust the interval as needed)

    return () => clearInterval(interval);
  }, [autoRefresh, props.description]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCheckboxChange = () => {
    setAutoRefresh(!autoRefresh);
  };
  const calculateRowHeight = (rowData: RowData) => {
    first.current = data[0].closeTime.toString();
    const lineHeight = 20;
    const additionalHeight = 10;
    return lineHeight * numberOfLinesInContent(rowData) + additionalHeight;
  };

  const numberOfLinesInContent = (rowData: RowData) => {
    // Placeholder logic, adjust based on your requirements
    return 1;
  };

  const handleFotprint = () => {
    props.setFotprint(!props.fotprint);

    fetchData();
  };

  return (
    <div>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={autoRefresh}
              onChange={handleCheckboxChange}
              name="connectSSE"
              //   trackColor={{ false: 'red', true: 'green' }}
              style={{ color: autoRefresh ? "green" : "red" }}
              inputProps={{ "aria-label": "Toggle For Connect with SSE" }}
            />
          }
          label={" Live"}
        />
      
        <ul
          style={{
            listStyleType: "none",
            margin: 0,
            padding: 0,
            display: "flex",
          }}
        >
          <li style={{ padding: "4px" }}>
            <label id="comment_max">
              {" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Max Vol:{" "}
              {addThousandSeparator(maxVolume.toFixed(0))}
            </label>
          </li>
          <li style={{ backgroundColor: "#edeec0", padding: "4px" }}>
            <label id="comment_mid">
              {" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Half Vol:{" "}
              {addThousandSeparator(midVolume.toFixed(0))}
            </label>
          </li>
          <li style={{ backgroundColor: "#caf0f8", padding: "4px" }}>
            <label id="comment_avg">
              {" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Avg Vol:{" "}
              {addThousandSeparator(avgVolume.toFixed(0))}
            </label>
          </li>
        </ul>
      </div>
      <CSVLink
  data={data}
  headers={[
    { label: 'Close Time', key: 'closeTime' },
    { label: 'Total Volume', key: 'totalVolume' },
    { label: 'Delta', key: 'delta' },
    { label: 'Open', key: 'open' },
    { label: 'Close', key: 'close' },
    { label: 'High', key: 'high' },
    { label: 'Low', key: 'low' },
    { label: 'POC', key: 'POC' },
  ]}
  filename={'your-exported-file.csv'}
>
  Export to CSV
</CSVLink>

      <Table width={800}>
        <TableHead>
          <TableRow>
            <TableCell>Close Time</TableCell>
            <TableCell>Total Volume</TableCell>
            <TableCell>Delta</TableCell>
            <TableCell>Open</TableCell>
            <TableCell>Close</TableCell>
            <TableCell>High</TableCell>
            <TableCell>Low</TableCell>
            <TableCell>POC</TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Table>
        <TableBody>
          <VariableSizeList
            height={400}
            width={800}
            itemCount={data.length}
            itemSize={(index) => calculateRowHeight(data[index])}
            overscanCount={10}
            onItemsRendered={({ visibleStartIndex, visibleStopIndex }) => {
              // Check if the last item is rendered
              const isLastItemVisible = visibleStopIndex === data.length - 1;
              const shouldFetchData = isLastItemVisible && !isFetching;

              dataSize.current = data.length;
              last.current = (
                dataSize.current > 0
                  ? data[dataSize.current - 1].closeTime
                  : "empty"
              ).toString();

              if (shouldFetchData && data.length < 5000) {
                fetchData();
              }
            }}
          >
            {({ index, style }) => (
              <Row
                index={index}
                style={style}
                data={data}
                midVolume={midVolume}
                avgVolume={avgVolume}
              />
            )}
          </VariableSizeList>
        </TableBody>
        {isFetching && (
          <TableBody>
            <TableRow>
              <TableCell colSpan={8}>Loading...</TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </div>
  );
};

export default FootprintWidget;
