import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import MuiTable from "components/BinanceFuture/Mui/MuiTable";
import * as React from "react";
import { useState } from "react";
import { PositionType, OrderHType, TradeHType } from "./Order.types";
// import { OrderService } from 'services';
import { Button, IconButton, Tooltip } from "@mui/material";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import {
  getOrderHistories,
  getPositions,
  getTradeHistories,
} from "components/BinanceFuture/Order/order.service";
// import { Label } from "@mui/icons-material";
// import Chart from "react-google-charts";
// import axios from "axios";
import TradingViewWidget from "./TradingViewWidget";
// import SseListener from "../Stream/FootprintWidget";
import CandleTimer from "../Stream/getNewTrade";
import FootprintWidget from "../Stream/getFootPrint";
import PivotWidget from "../Stream/getPivot";

interface HeadCell {
  disablePadding: boolean;
  id: keyof PositionType;
  label: string;
  numeric: boolean;
}
interface resPonse {
  open?: number;
  sl?: number;
  symbol?: string;
  tp?: number;
  side?: boolean;
  openTime?: number /* other properties */;
  POC?: number;
  avgPOC?: number;
}
// class FLOW {
//   constructor() {
//     this.trend = 0.0;
//     this.trendColor = 0;
//     this.action = 0;
//   }
// }

// type KlineData = [number, string, string, string, string, string, string, number, string, number, string, string, string, number, string, string, number, string, number, string, string, number, number, number, number, string];

interface OrderCell {
  disablePadding: boolean;
  id: keyof OrderHType;
  label: string;
  numeric: boolean;
}
interface TradeCell {
  disablePadding: boolean;
  id: keyof TradeHType;
  label: string;
  numeric: boolean;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const tradeCells: readonly TradeCell[] = [
  {
    id: "time",
    numeric: true,
    disablePadding: false,
    label: "Time",
  },
  {
    id: "side",
    numeric: false,
    disablePadding: false,
    label: "Side",
  },

  {
    id: "symbol",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "orgQty",
    numeric: false,
    disablePadding: false,
    label: "Qty(USDT)",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "leverage",
    numeric: false,
    disablePadding: false,
    label: "PNL",
  },
  {
    id: "types",
    numeric: false,
    disablePadding: false,
    label: "Commission",
  },
  {
    id: "orderId",
    numeric: false,
    disablePadding: false,
    label: "OrderID",
  },
  // {
  //   id: "commission",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Commission",
  // },
  // time: string, //hidden for reverse sort
  // side: string,
  // symbol: string,
  // orderId: string,
  // qty_USDT: string, //size
  // price: string,
  // realizedPnl: string,
  // commission:string,
];
const headCells: readonly HeadCell[] = [
  {
    id: "time",
    numeric: true,
    disablePadding: false,
    label: "Time",
  },
  {
    id: "side",
    numeric: false,
    disablePadding: false,
    label: "Side",
  },

  {
    id: "symbol",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "positionAmt",
    numeric: false,
    disablePadding: true,
    label: "Size",
  },
  {
    id: "entryPrice",
    numeric: false,
    disablePadding: false,
    label: "Entry Price",
  },
  {
    id: "markPrice",
    numeric: false,
    disablePadding: false,
    label: "Mark Price",
  },
  {
    id: "leverage",
    numeric: false,
    disablePadding: false,
    label: "Leverage",
  },
  {
    id: "liquidationPrice",
    numeric: false,
    disablePadding: false,
    label: "Liq. Price",
  },
  {
    id: "unRealizedProfit",
    numeric: false,
    disablePadding: false,
    label: "PNL",
  },
];

const orderCells: readonly OrderCell[] = [
  {
    id: "time",
    numeric: true,
    disablePadding: false,
    label: "Time",
  },
  {
    id: "side",
    numeric: false,
    disablePadding: false,
    label: "Side",
  },
  {
    id: "types",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "symbol",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "orgQty",
    numeric: false,
    disablePadding: true,
    label: "Size",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Entry Price",
  },
  {
    id: "leverage",
    numeric: false,
    disablePadding: false,
    label: "Leverage",
  },
  {
    id: "orderId", // set hidden later !!!
    numeric: false,
    disablePadding: false,
    label: "orderId",
  },
];
// function sma(values:any, period:any) {
//   if (values.length < period) {
//     return null;
//   }

//   const sum = values.slice(0, period).reduce((acc:any, val:any) => acc + val, 0);
//   return sum / period;
// }
// function stdev(values:any, period:any) {
//   if (values.length < period) {
//     return null;
//   }

//   const mean = sma(values, period);
//   const squaredDiffs = values
//     .slice(0, period)
//     .map((val:any) => (val - mean) ** 2);
//   const variance = sma(squaredDiffs, period);

//   return Math.sqrt(variance);
// }
type PropType = {
  // rows: OrderType[]; //array of objects
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  symbol: string;
  setSymbol: React.Dispatch<React.SetStateAction<string>>;
  setIndexProp: React.Dispatch<React.SetStateAction<number>>;
  balance: string;
  positions: PositionType[];
  orderHistories: OrderHType[];
  tradeHistories: TradeHType[];
  setBalance: React.Dispatch<React.SetStateAction<string>>;
  setPositions: React.Dispatch<React.SetStateAction<PositionType[]>>;
  setOrderHistories: React.Dispatch<React.SetStateAction<OrderHType[]>>;
  setTradeHistories: React.Dispatch<React.SetStateAction<TradeHType[]>>;
  price: string;
  setPrice: React.Dispatch<React.SetStateAction<string>>;
  sl: string;
  setSl: React.Dispatch<React.SetStateAction<string>>;
  tp1: string;
  setTp1: React.Dispatch<React.SetStateAction<string>>;
  tp2: string;
  setTp2: React.Dispatch<React.SetStateAction<string>>;
  setLisener: React.Dispatch<React.SetStateAction<boolean>>;
  lisener: boolean;
  setFotprint: React.Dispatch<React.SetStateAction<boolean>>;
  fotprint: boolean;
  // handleChangesetValueTaps:React.Dispatch<React.SetStateAction<string>>;
  resP: resPonse[];
  setResP: React.Dispatch<React.SetStateAction<resPonse[]>>;
};

const OrderTable = (props: PropType) => {
  const {
    setIndexProp,
    description,
    // setDescription,
    balance,
    setBalance,
    setOrderHistories,
    setPositions,
    setTradeHistories,
    positions,
    orderHistories,
    tradeHistories,
    symbol,
    setSymbol,
    price,
    setPrice,
    sl,
    setSl,
    tp1,
    setTp1,
    tp2,
    setTp2,
    lisener,
    setLisener,
    fotprint,
    setFotprint,
    resP,
    setResP,
  } = props;

  // const fl = () => {
  //   const flo = new FLOW();
  //   const BBperiod = 21;
  //   const BBdeviations = 1.0;
  //   const UseATRfilter = true;
  //   const ATRperiod = 5;
  //   const hl = false;

  //   const BBUpper =
  //     sma(close, BBperiod) + stdev(close, BBperiod) * BBdeviations;
  //   const BBLower =
  //     sma(close, BBperiod) - stdev(close, BBperiod) * BBdeviations;

  //   let TrendLine = 0.0;
  //   let iTrend = 0.0;
  //   let buy = 0.0;
  //   let sell = 0.0;

  //   const BBSignal = close > BBUpper ? 1 : close < BBLower ? -1 : 0;

  //   if (BBSignal === 1 && UseATRfilter === 1) {
  //     TrendLine = low - ta.atr(ATRperiod);
  //     if (TrendLine < TrendLine[1]) {
  //       TrendLine = TrendLine[1];
  //     }
  //   }

  //   if (BBSignal === -1 && UseATRfilter === 1) {
  //     TrendLine = high + ta.atr(ATRperiod);
  //     if (TrendLine > TrendLine[1]) {
  //       TrendLine = TrendLine[1];
  //     }
  //   }

  //   if (BBSignal === 0 && UseATRfilter === 1) {
  //     TrendLine = TrendLine[1];
  //   }

  //   if (BBSignal === 1 && UseATRfilter === 0) {
  //     TrendLine = low;
  //     if (TrendLine < TrendLine[1]) {
  //       TrendLine = TrendLine[1];
  //     }
  //   }

  //   if (BBSignal === -1 && UseATRfilter === 0) {
  //     TrendLine = high;
  //     if (TrendLine > TrendLine[1]) {
  //       TrendLine = TrendLine[1];
  //     }
  //   }

  //   if (BBSignal === 0 && UseATRfilter === 0) {
  //     TrendLine = TrendLine[1];
  //   }

  //   iTrend = iTrend[1];

  //   if (TrendLine > TrendLine[1]) {
  //     iTrend = 1;
  //   }

  //   if (TrendLine < TrendLine[1]) {
  //     iTrend = -1;
  //   }

  //   buy = iTrend[1] === -1 && iTrend === 1 ? 1 : null;
  //   sell = iTrend[1] === 1 && iTrend === -1 ? 1 : null;

  //   flo.trend = TrendLine;
  //   flo.trendColor = iTrend > 0 ? 1 : -1;
  //   flo.action =
  //     buy === 1 && hl === false ? 1 : sell === 1 && hl === false ? -1 : 0;

  //   return flo;
  // };

  const [value, setValue] = useState(0);

  // const [chartData, setChartData] = useState<Array<Array<any>>>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const refreshPositionHistory = async () => {
    const positions = await getPositions(description).then((res) => {
      // console.log("then3")
      return res;
    });
    const orderHistories = await getOrderHistories(description);
    const tradeHistories = await getTradeHistories(description);
    setOrderHistories(orderHistories);
    setPositions(positions);

    setTradeHistories(tradeHistories);
  };

  const [orderBy, setOrderBy] = React.useState<keyof PositionType>("time");
  return (
    <Grid item xs={4} style={{ backgroundColor: "white", height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {/* <Button size='small' variant='contained' color='primary' onClick={refreshPositionHistory}>Refresh</Button> */}

        <Stack direction="row" spacing={2} justifyContent="space-between">

          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Position" {...a11yProps(0)} />
            <Tab label="Order History" {...a11yProps(1)} />
            <Tab label="Trade History" {...a11yProps(2)} />
            <Tab label="Chart" {...a11yProps(3)} />
           
              {/* <Tab label="footprint" {...a11yProps(4)} /> */}
            
              <Tab label="print" {...a11yProps(4)} />
         
     
              <Tab label="Pivot" {...a11yProps(5)} />
          
          </Tabs>
          <Tooltip title="Refresh">
            <IconButton aria-label="refresh" onClick={refreshPositionHistory}>
              <RefreshRoundedIcon />
            </IconButton>
          </Tooltip>
        </Stack>

        {/* ///// */}
      </Box>
      <TabPanel value={value} index={0}>
        <MuiTable
          PorH="p" //positions
          setSymbol={setSymbol}
          rows={positions}
          // orderBy={orderBy}
          // setOrderBy={setOrderBy}
          headCells={headCells}
          description={description}
          balance={balance}
          setBalance={setBalance}
          setPositions={setPositions}
          setOrderHistories={setOrderHistories}
          setTradeHistories={setTradeHistories}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MuiTable
          PorH="o" //orderHitories
          rows={orderHistories}
          setSymbol={setSymbol}
          // orderBy={orderBy} setOrderBy={setOrderBy}
          headCells={orderCells}
          description={description}
          balance={balance}
          setBalance={setBalance}
          setPositions={setPositions}
          setOrderHistories={setOrderHistories}
          setTradeHistories={setTradeHistories}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MuiTable
          PorH="t" //TradeHitories
          setSymbol={setSymbol}
          rows={tradeHistories}
          headCells={tradeCells}
          description={description}
          balance={balance}
          setBalance={setBalance}
          setPositions={setPositions}
          setOrderHistories={setOrderHistories}
          setTradeHistories={setTradeHistories}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        {symbol !== "" && (
          <div style={{ height: "80vh" }}>
            <TradingViewWidget symbolName={"BINANCE:" + symbol + ".P"} />
          </div>
        )}{" "}
      </TabPanel>
    { /* <TabPanel value={value} index={4}>
        <SseListener
          setIndexProp={setIndexProp}
          setSymbol={setSymbol}
          price={price}
          setPrice={setPrice}
          sl={sl}
          setSl={setSl}
          tp1={tp1}
          setTp1={setTp1}
          tp2={tp2}
          setTp2={setTp2}
        /> 
         <CandleTimer

          resP={resP}
          setResP={setResP}
          lisener={lisener}
          setLisener={setLisener}
          fotprint={fotprint}
          setFotprint={setFotprint}
          description={description}
          setIndexProp={setIndexProp}
          setSymbol={setSymbol}
          price={price}
          setPrice={setPrice}
          sl={sl}
          setSl={setSl}
          tp1={tp1}
          setTp1={setTp1}
          tp2={tp2}
          setTp2={setTp2}
          tap="TRADE"
        /> 
      </TabPanel>*/}
      <TabPanel value={value} index={4}>
        <FootprintWidget
          
          lisener={lisener}
          setLisener={setLisener}
          fotprint={fotprint}
          setFotprint={setFotprint}
          description={description}
          setIndexProp={setIndexProp}
          setSymbol={setSymbol}
          price={price}
          setPrice={setPrice}
          sl={sl}
          setSl={setSl}
          tp1={tp1}
          setTp1={setTp1}
          tp2={tp2}
          setTp2={setTp2} />
 
      </TabPanel>
      <TabPanel value={value} index={5}>
   
            <PivotWidget
          
          lisener={lisener}
          setLisener={setLisener}
          // fotprint={fotprint}
          // setFotprint={setFotprint}
          description={description}
          setIndexProp={setIndexProp}
          setSymbol={setSymbol}
          price={price}
          setPrice={setPrice}
          sl={sl}
          setSl={setSl}
          tp1={tp1}
          setTp1={setTp1}
          tp2={tp2}
          setTp2={setTp2} />
 
      </TabPanel>
    </Grid>
  );
};

export default OrderTable;

// TradingViewWidget.jsx

// import React, { useEffect, useRef } from 'react';
