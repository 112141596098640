import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { getNewTrade } from "./Order/order.service";

// import { makeStyles } from "@material-ui/core";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
// import { getOrderHistories, getPositions } from 'services/order.service';
// import { Link, useNavigate } from "react-router-dom";
import Order from "./Order";
// import SseLisener from "./Stream";
import { PositionType, OrderHType, TradeHType } from "./Order/Order.types";
import OrderTable from "components/BinanceFuture/Order/OrderTable";
import {
  getBalance,
  getOrderHistories,
  getTradeHistories,
  getPositions,getLastPrice
} from "components/BinanceFuture/Order/order.service";
// import { AuthService } from "services";
// import userSchema from ".";
interface KlineData {
  0: number; // Timestamp
  1: string; // Open price
  2: string; // High price
  3: string; // Low price
  4: string; // Close price
  // ... other properties
}
interface resPonse {
  open?: number;
  sl?: number;
  symbol?: string;
  tp?: number;
  side?: boolean;
  openTime?: number /* other properties */;
  POC?:number;
  avgPOC?:number;
}
// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     padding: theme.spacing(2),
//     "& .MuiTextField-root": {
//       margin: theme.spacing(1),
//       width: "300px",
//     },
//     "& .MuiButtonBase-root": {
//       margin: theme.spacing(2),
//     },
//   },
// }));

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BinanceFuture = () => {
  const [loading, setLoading] = useState(false);
  const [lisener, setLisener] = useState(true);
  const [fotprint, setFotprint] = useState(true);
  const [resP, setResP] = useState<resPonse[]>([]);
  // const resP = useRef<resPonse>({});

  const [price, setPrice] = useState("");
  const [sl, setSl] = useState("");
  const [tp1, setTp1] = useState("");
  const [tp2, setTp2] = useState("");
  const [indexProp, setIndexProp] = useState(0);
  
  // const [message, setMessage] = useState("");
  // const navigate = useNavigate();
  // const classes = useStyles();
  // const [submitLoading, setSubmitLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [symbol, setSymbol] = useState("");
  const [description, setDesciption] = useState("");
  const [fixed, setFixed] = useState(false);
  const [fixedLost, setFixedLost] = useState("");
  const [lastValue, setLastValue] = useState(0);
  const [balance, setBalance] = useState("");
  const [positions, setPositions] = useState<PositionType[]>([]);
  const [orderHistories, setOrderHistories] = useState<OrderHType[]>([]);
  const [tradeHistories, setTradeHistories] = useState<TradeHType[]>([]);

  // const setData = async () => {
  //     await getNewTrade(description).then((data) => {
  //       console.log(Date.now());
  
  //       let res = data;
  //       setResP(res);
  //       const decimalLength = (res[0]?.open.toString().split(".")[1] || "").length;
  //       // console.log(res);
  //       // setSymbol(data.symbol);
  //       setPrice(res[0].open);
  //       setSymbol(res[0].symbol);
  //       setIndexProp(res[0].side ? 0 : 1);
  //       setSl(res[0].sl.toFixed(decimalLength));
  //       setTp1(res[0].tp.toFixed(decimalLength));
  //       setTp2(res[0].tp.toFixed(decimalLength));
  //     });
  //   };
    const [nextCandleTimestamp, setNextCandleTimestamp] = useState(0);

    function timeStampConvert(timestamp: any) {
      const date = new Date(timestamp);
      const options = {
        timeZone: 'America/Vancouver',
        year: 'numeric' as const,   // Fix type error here
        month: '2-digit' as const,  // Fix type error here
        day: '2-digit' as const,    // Fix type error here
        hour: 'numeric' as const,
        minute: 'numeric' as const,
        second: 'numeric' as const,
      };
    
      return date.toLocaleString('en-US', options);
    }
 useEffect(() => {
    const fetchData = async () => {
      try {
        if (symbol !== "") {
          const response = await getLastPrice("Price", symbol)
         

          console.log("kkkkkkkkk ", response.data.price);

          // setChartData([["Time", "Open", "Close", "High", "Low"], ...chartData]);
          setLastValue(response.data.price);
        }
        // setChartData([["Time", "Open", "Close", "High", "Low"], ...chartData]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [symbol, positions, orderHistories, tradeHistories]);
  
  // useEffect(() => { amir
  //   refreshPositionHistory()
  // }, [description])

  // useEffect(() => {
  //   if (refresh || !loading) {
  //     fetchTablesData()
  //   }
  // }, [loading, refresh])

  useEffect(() => {
    const refreshBalance = async () => {
      await getBalance(description).then((res: any) =>
        setBalance(res && res[0] ? res[0]?.balance : "-1")
      );
    };
    refreshBalance();
  }, [positions, orderHistories, tradeHistories]);

  const refreshPositionHistory = async () => {
    const positions = await getPositions(description);
    const orderHistories = await getOrderHistories(description);
    const tradeHistories = await getTradeHistories(description);
    setOrderHistories(orderHistories);
    setTradeHistories(tradeHistories);
    setPositions(positions);
  };
  function setindexprop(i: number) {
    setIndexProp(i);
    return i;
  }
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  interface StyledTabProps {
    index: number;
  }

  // const AntTab = styled((props: StyledTabProps) => (
  //   <Tab disableRipple
  //   {...props}
  //   />
  // ))(({ theme }) => ({
  //   textTransform: 'none',
  //   fontWeight: theme.typography.fontWeightRegular,
  //   fontSize: theme.typography.pxToRem(15),
  //   marginRight: theme.spacing(1),
  //   color: 'rgba(255, 255, 255, 0.7)',
  //   '&.Mui-selected': {
  //     color: '#fff',
  //   },
  //   '&.Mui-focusVisible': {
  //     backgroundColor: 'rgba(100, 95, 228, 0.32)',
  //   },
  // }));

  const AntTabs = styled(Tabs)({
    borderBottom: `1px solid pink`,
    // '& .MuiTabs-indicator': {
    //   backgroundColor: 'red',
    // },
  });

  // con st { data: positionsData } = UsePositions(description, loading, refresh);
  // const orderHistories = getOrderHistories(description)
  // console.log("posiii:", positions, orderHistories)

  return (
    <Grid container spacing={2} style={{ height: "100%" }}>
      <Grid item xs={12} md={8} order={{ xs: 2, sm: 1 }} direction="column">
        <OrderTable
          // rows={positions}
          // fixed={fixed}
          // fixedLost={fixedLost}
          // setFixed={setFixed}
          setResP={setResP}
          resP={resP}
          setIndexProp={setIndexProp}
          setDescription={setDesciption}
          setSymbol={setSymbol}
          symbol={symbol}
          description={description}
          balance={balance}
          setBalance={setBalance}
          // refreshPositionHistory={handleRefresh}
          positions={positions}
          orderHistories={orderHistories}
          tradeHistories={tradeHistories}
          setPositions={setPositions}
          setOrderHistories={setOrderHistories}
          setTradeHistories={setTradeHistories}
          price={price}
          setPrice={setPrice}
          sl={sl}
          setSl={setSl}
          tp1={tp1}
          setTp1={setTp1}
          tp2={tp2}
          setTp2={setTp2}
          setLisener={setLisener}
          lisener={lisener}
          setFotprint={setFotprint}
          fotprint={fotprint}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        order={{ xs: 1, sm: 2 }}
        direction="column"
        alignItems="left"
        justifyContent="flex-end"
        width="20%"
      >
        <Grid
          item
          xs={3}
          style={{ backgroundColor: "white", justifyContent: "left" }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              justifyContent: "left",
            }}
          >
            <AntTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Buy" {...a11yProps(0)} />
              <Tab label="Sell" {...a11yProps(1)} />
              <p>{lastValue}</p>
            </AntTabs>
          </Box>
          <TabPanel value={value} index={0}>
            
            <Order
              lastValue={lastValue}
              fixed={fixed}
              fixedLost={fixedLost}
              setFixed={setFixed}
              setFixedLost={setFixedLost}
              indexProp={0}
              loading={loading}
              setLoading={setLoading}
              description={description}
              symbol={symbol}
              setSymbol={setSymbol}
              setDescription={setDesciption}
              balance={balance}
              setBalance={setBalance}
              setPositions={setPositions}
              setOrderHistories={setOrderHistories}
              setTradeHistories={setTradeHistories}
              price={price}
              setPrice={setPrice}
              sl={sl}
              setSl={setSl}
              tp1={tp1}
              setTp1={setTp1}
              tp2={tp2}
              setTp2={setTp2}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            
            <Order
              lastValue={lastValue}
              fixed={fixed}
              fixedLost={fixedLost}
              setFixed={setFixed}
              setFixedLost={setFixedLost}
              indexProp={1}
              loading={loading}
              setLoading={setLoading}
              description={description}
              symbol={symbol}
              setSymbol={setSymbol}
              setDescription={setDesciption}
              balance={balance}
              setBalance={setBalance}
              setPositions={setPositions}
              setOrderHistories={setOrderHistories}
              setTradeHistories={setTradeHistories}
              price={price}
              setPrice={setPrice}
              sl={sl}
              setSl={setSl}
              tp1={tp1}
              setTp1={setTp1}
              tp2={tp2}
              setTp2={setTp2}
              // refreshPositionHistory={handleRefresh}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BinanceFuture;
