import React, { useState, useEffect } from "react";
import Link from "@mui/material/Link";

// import React, { useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Table,
  Button,
  TableBody,
  Radio,
  Tooltip,
  Paper,
  IconButton,
  Toolbar,
  TableSortLabel,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TextField,
} from "@mui/material";
import CancelPresentationTwoToneIcon from "@mui/icons-material/CancelPresentationTwoTone";
import DoneIcon from "@mui/icons-material/DoneRounded";
import RevertIcon from "@mui/icons-material/DoDisturbAlt";
import EditIcon from "@mui/icons-material/Edit";
import { visuallyHidden } from "@mui/utils";
import {
  PositionType,
  OrderHType,
  TradeHType,
} from "components/BinanceFuture/Order/Order.types";
import {
  closeOrder,
  closePosition,
  getOrderHistories,
  getTradeHistories,
  getPositions,
} from "components/BinanceFuture/Order/order.service";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const Root = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    width: "400px",
  },
  [theme.breakpoints.down("md")]: {
    width: "700px",
  },
  [theme.breakpoints.up("md")]: {
    width: "900px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "1100px",
  },
}));

type Order = "asc" | "desc";

interface EnhancedTableProps {
  numSelected: number;

  rowCount: number;
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  selected: Selected;
}

interface IProps<T> {
  rows: PositionType[] | OrderHType[] | TradeHType[];
  setSymbol: React.Dispatch<React.SetStateAction<string>>;
  headCells: any;
  description: string;
  balance: string;
  setBalance: React.Dispatch<React.SetStateAction<string>>;
  setOrderHistories: React.Dispatch<React.SetStateAction<OrderHType[]>>;
  setTradeHistories: React.Dispatch<React.SetStateAction<TradeHType[]>>;
  setPositions: React.Dispatch<React.SetStateAction<PositionType[]>>;

  PorH: string;
}

type Selected = {
  symbol: string;
  size: string | undefined;
  side: string;
  orderId: string | undefined;
  time: string;
};
export default function EnhancedTable<T extends object>(props: IProps<T>) {
  const {
    rows,
    // orderBy,
    // setOrderBy,
    headCells,
    description,
    // balance,
    setBalance,
    setSymbol,
    setPositions,
    setOrderHistories,
    setTradeHistories,
    PorH,
  } = props;

  const [price, setPrice] = useState("");
  const [order, setOrder] = useState<Order>("asc");
  const [selected, setSelected] = useState<Selected>({
    symbol: "",
    size: "",
    side: "",
    orderId: "0",
    time: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChange = (e: any) => {
    const regex = /^[0-9.\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setPrice(e.target.value);
    }
  };
  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell align="center" padding="normal" width={"1px"}></TableCell>
          {PorH === "o" && (
            <TableCell
              width={"1px"}
              align="center"
              padding="normal"
            ></TableCell>
          )}
          {headCells.map((headCell: any) => (
            <TableCell
              key={headCell.id}
              align="center"
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const refreshPositions = async () => {
    const positions = await getPositions(description);
    setPositions(positions);
  };
  function handleSymbolClick(symbol: string) {
    setSymbol(symbol);
  }
  const refreshOrderHistories = async () => {
    const orderHistories = await getOrderHistories(description);
    setOrderHistories(orderHistories);
  };
  const refreshTradeHistories = async () => {
    const tradeHistories = await getTradeHistories(description);
    setTradeHistories(tradeHistories);
  };
  const onDoneEditMode = async (
    e: React.MouseEvent<HTMLElement>,
    row: OrderHType,
    edit: boolean,
    change: string
  ) => {
    onToggleEditMode(row.orderId);
    if (
      edit === true &&
      row.types! !== "LIMIT" &&
      row.price !== change &&
      change !== ""
    ) {
      await closeOrder(
        row.symbol,
        row.orderId,
        description,
        change,
        row.types!,
        row.side,
        row.orgQty!
      ).then(
        () => {
          refreshOrderHistories();
        },
        (error: any) => {}
      );
    }
    setPrice("");
  };

  const onToggleEditMode = (id: string = "") => {
    setOrderHistories(() => {
      return rows.map((row) => {
        if (row.orderId === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      }) as OrderHType[];
    });
  };

  function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected, selected } = props;

    const handleClose = async () => {
      if (PorH === "p") {
        await closePosition(
          selected.symbol,
          selected?.size,
          selected.side,
          description
        ).then(
          () => {
            refreshPositions();
          },
          (error: any) => {}
        );
      } else if (PorH === "o") {
        await closeOrder(
          selected.symbol,
          selected.orderId,
          description,
          "0",
          "0",
          "0",
          "0",
        ).then(
          () => {
            refreshOrderHistories();
          },
          (error: any) => {}
        );
      }
    };

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Tooltip title="Close">
            <Button
              variant="outlined"
              size="small"
              startIcon={<CancelPresentationTwoToneIcon />}
              onClick={handleClose}
            >
              Close
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>{/* <FilterListIcon /> */}</IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  }

  const handleClick = (
    event: React.MouseEvent<unknown>,
    row: PositionType | OrderHType
  ) => {
    setSelected({
      symbol: row.symbol,
      size: PorH === "p" ? row.positionAmt : "0",
      side: PorH === "p" ? row.side : "",
      orderId: PorH === "p" ? "0" : row.orderId,
      time: PorH === "p" ? row.time : (row as OrderHType).orderId,
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected?.time === name;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.time.length > 1 ? 1 : 0}
          selected={selected}
        />

        <TableContainer>
          <Table
            sx={{
              width: "max-content",
            }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              numSelected={selected.time.length > 1 ? 1 : 0}
              rowCount={rows?.length ? rows?.length : 0}
            />
            <TableBody>
              {rows &&
                rows
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const isItemSelected = isSelected(
                      PorH === "o" ? (row as OrderHType).orderId : row.time
                    );
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const shouldRedColor =
                      PorH === "t" && row["leverage"] < "0";
                    const shouldGreenColor =
                      PorH === "t" && row["leverage"] > "0";
                    return (
                        <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="radio"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={PorH === "o" ? (row as OrderHType).orderId : row.time}
                        selected={isItemSelected}
                        sx={{
                          backgroundColor: shouldRedColor
                            ? "rgba(255, 25, 0, 0.1)"
                            : shouldGreenColor
                            ? "rgba(55, 215, 0, 0.1)"
                            : "inherit",
                        }}
                      >
                        {PorH === "o" && (
                          <TableCell align="center">
                            {row.isEditMode ? (
                              <>
                                <IconButton
                                  aria-label="done"
                                  onClick={(e) =>
                                    onDoneEditMode(e, row as OrderHType, true, price)
                                  }
                                >
                                  <DoneIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="cancel"
                                  onClick={(e) =>
                                    onDoneEditMode(e, row as OrderHType, false, "0")
                                  }
                                >
                                  <RevertIcon />
                                </IconButton>
                              </>
                            ) : (
                              <IconButton
                                aria-label="edit"
                                onClick={(e) => onToggleEditMode(row.orderId)}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        )}
                        <TableCell padding="checkbox">
                          <Radio
                            checked={isItemSelected}
                            defaultChecked={index === 0}
                            name="radio-button-demo"
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        {Object.entries(row).map(([key, value]) =>
                          row.isEditMode && PorH === "o" && key === "price" ? (
                            <TableCell key={key} align="center">
                              <TextField
                                label={(row as OrderHType).price}
                                variant="filled"
                                style={{ width: "80px" }}
                                onChange={(e: any) => handleChange(e)}
                              />
                            </TableCell>
                          ) : (
                            <TableCell
                              key={key}
                              align="center"
                              style={{
                                color:
                                  (PorH === "p" && key === "side" && value === "LONG") ||
                                  (PorH === "p" && key === "side" && value === "SHORT")
                                    ? value === "LONG"
                                      ? "green"
                                      : "red"
                                    : "inherit",
                              }}
                            >
                              {key === "symbol" ? (
                                <Link
                                  component="button"
                                  variant="body2"
                                  onClick={() => handleSymbolClick(row.symbol)}
                                >
                                  {row.symbol}
                                </Link>
                              ) : (
                                key === "Qty(USDT)" && PorH === "t" && rows[index + 1]?.symbol === row.symbol
                                  ? "Total Header"
                                  : key === "PNL" && PorH === "t" && rows[index + 1]?.symbol === row.symbol
                                  ? "New PNL Value"
                                  : key === "Commission" &&
                                    PorH === "t" &&
                                    rows[index + 1]?.symbol === row.symbol
                                  ? "New Commission Value"
                                  : value
                              )}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                      
                      
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
