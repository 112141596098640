import { AxiosError } from "axios";
// import { useQuery, useMutation, QueryCache } from "react-query";
import api from "../../../services/api2";
// import useSWR from "swr";
// import useSWRImmutable from 'swr/immutable'
// import { OrderType } from "components/Order/Order.types";
import authHeader from "../../../services/ auth_header";
import { AxiosResponse } from 'axios'; // Import AxiosResponse type from axios library

const BF_MAKE_ORDER_URL = "bf/trade/newOrder";
const BF_CLOSE_POSITION_URL = "bf/trade/closePositionNow";
const BF_CLOSE_ORDER_URL = "bf/trade/cancelOrder";
const BF_GET_BALANCE_URL = "bf/trade/getBalance";
const GET_NEW_TRADE_URL = "bf/trade/getnewtrade";
// const GET_POSITIONS_URL = "http://5.2.73.181:3001/api/trade/getCurrentPositions"; //development address
const BF_GET_POSITIONS_URL = "bf/trade/getCurrentPositions";
// const GET_ORDERHISTORIES_URL = "http://5.2.73.181:3001/api/trade/getCurrentPositions"; //development address
const BF_GET_ORDERHISTORIES_URL = "bf/trade/getOrderHistories";
const BF_GET_TRADEHISTORIES_URL = "bf/trade/getTradeHistories";
const BF_GET_FOOTPRINT_URL = "bf/trade/getfootprint";
const BF_GET_PIVOT_URL = "bf/trade/getPivot";
const BF_GET_EXCHANGE_URL = "bf/trade/getexchangeInfo";
const BF_GET_LAST_PRICE_URL = "bf/trade/getlastprice";

export async function makeOrder(
  isBuy: boolean,
  symbol: string,
  limit: string,
  leverage: string,
  price: string,
  qty: string,
  description: string,
  sl: string,
  tp1: string,
  tp2: string,
  lastValue: string,
  closePosition: boolean,
  quantityPrecision: string
) {
  return await api
    .post_(
      BF_MAKE_ORDER_URL,
      {
        side: isBuy ? "BUY" : "SELL",
        symbol,
        type: limit,
        leverage,
        price,
        qty,
        acc_name: description,
        sl,
        tp1,
        tp2,
        lastValue,
        closePosition,
        quantityPrecision,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      // console.log("res make order:", response);
    });
}

export async function getPositions(description: string) {
  try {
    return await api
      .get(BF_GET_POSITIONS_URL + "?acc_name=" + description, {
        headers: authHeader(),
      })
      .then((response) => {
        // console.log("then2");
        return response.positionssend;
      });
  } catch (error) {
    return null;
  }
}
export async function getNewTrade(description: string) {
  return await api
    .get(GET_NEW_TRADE_URL + "?acc_name=" + description, {
      headers: authHeader(),
    })
    .then((response:any) => {
      return response;
    });
}
export async function getOrderHistories(description: string) {
  return await api
    .get(BF_GET_ORDERHISTORIES_URL + "?acc_name=" + description, {
      headers: authHeader(),
    })
    .then((response) => {
      // console.log("response order histories:", response);
      return response;
    });
}
export async function getTradeHistories(description: string) {
  return await api
    .get(BF_GET_TRADEHISTORIES_URL + "?acc_name=" + description, {
      headers: authHeader(),
    })
    .then((response) => {
      // console.log("response order histories:", response);
      return response;
    });
}

export async function getBalance(description: string) {
  return await api
    .get(BF_GET_BALANCE_URL + "?acc_name=" + description, {
      headers: authHeader(),
    })
    .then((response) => {
      // console.log("response order histories:", response);
      return response;
    });
}
export async function getFootPrintApi(description: string,status:string,date:string,symbol:string) {
  return await api
    .get(BF_GET_FOOTPRINT_URL + "?acc_name=" + description+"&symbol="+symbol+"&status="+status+"&timestamp="+date, {
      headers: authHeader(),
    })
    .then((response) => {
      // console.log("response order histories:", response);
      return response;
    });
}


export async function getExchangeInfo(description: string): Promise<AxiosResponse> {
  try {
    const response = await api.get(BF_GET_EXCHANGE_URL, {
      params: { acc_name: description }, // Use params object for query parameters
      headers: authHeader(),
    });

    // console.log("ll",response); // Log the response data
    
    return response 
  } catch (error) {
    console.error("Error fetching exchange info:", error);
    throw error; // Re-throw the error to be handled elsewhere
  }
}
export async function getPivotApi(description: string,symbol:string,change:boolean) {
  return await api
    .get(BF_GET_PIVOT_URL + "?acc_name=" + description+"&symbol="+symbol+"&new="+change, {
      headers: authHeader(),
    })
    .then((response) => {
      // console.log("response order histories:", response);
      return response;
    });
}
export async function getLastPrice(description: string,symbol:string) {
  return await api
    .get(BF_GET_LAST_PRICE_URL + "?acc_name=" + description+"&symbol="+symbol, {
      headers: authHeader(),
    })
    .then((response) => {
      // console.log("response order histories:", response);
      return response;
    });
}
// export function usePositions() {
//   const STALE_TIME = 10 * 60 * 1000;
//   return useQuery<OrderType[], AxiosError>(QUERY_KEY, getPositions, {  staleTime: STALE_TIME});
// }

// export function UsePositions(description: string, loading: boolean, refresh: boolean) {
//   // const STALE_TIME = 10 *60 * 1000;
//   return useSWRImmutable([QUERY_KEY, loading, refresh],
//     () => getPositions(description), {
//     // refreshInterval: STALE_TIME,
//     // revalidateOnReconnect: true,
//   });

export async function closePosition(
  symbol: string,
  qty: string | undefined,
  side: string,
  description: string
) {
  return await api
    .post_(
      BF_CLOSE_POSITION_URL,
      {
        symbol,
        qty: Number(qty),
        acc_name: description,
        side,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
}

export async function closeOrder(
  symbol: string,
  orderId: string | undefined,
  description: string,
  change: string,
  type: string,
  side: string,
  orgQty: string
) {
  return await api
    .post_(
      BF_CLOSE_ORDER_URL,
      {
        symbol,
        orderId: orderId,
        acc_name: description,
        change,
        type,
        side,
        orgQty,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
}
