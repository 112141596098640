import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .trim()
    .required("Username is required")
    .matches(/^[a-zA-Z0-9_.-]{3,64}$/, "Invalid username"),

  // email: Yup.string()
  //   .trim()
  //   .matches(
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/,
  //     "آدرس ایمیل نامعتبر است"
  //   )
  //   .matches(/^.{1,60}$/, "آدرس ایمیل نامعتبر است"),

  password: Yup.string()
    .trim()
    .required("Password is required")
    .matches(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$_%^&*]{8,16}$/,
    // .matches(/^.{8,25}$/, 
      "Invalid password"
      // " آدرس پستي نامعتبر است (حداکثر 255 کاراکتر)"
      )
});
