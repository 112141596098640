import * as Yup from "yup";

const schema = Yup.object().shape({
  username: Yup.string()
    .trim()
    .required("نام کسب و کار را وارد نمایید")
    .matches(/^.{1,50}$/, "نام کسب و کار نامعتبر است (حداکثر 50 کاراکتر) "),

  // username: Yup.string()
  //   .trim()
  //   .required("نام کاربری را وارد نمایید")
  //   .matches(/^[a-zA-Z0-9_.-]{3,64}$/, "نام کاربری وارد شده نامعتبر است"),

  password: Yup.string()
  .trim()
  .required("شماره همراه را وارد نمایید")
  .matches(/^(09|\u06F0\u06F9)([\u06F0-\u06F9\s0-9]){9}$/, "شماره همراه وارد شده نامعتبر است"),

  email: Yup.string()
    .trim()
    // .required("آدرس ایمیل را وارد نمایید")
    .matches(
      /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "آدرس ایمیل نامعتبر است"
    )
    .matches(/^.{0,60}$/, "آدرس ایمیل نامعتبر است"),
  // .email("آدرس ایمیل نامعتبر است"),

});

export default schema;
