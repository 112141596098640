import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";

const ProtectedRoute = (props: any) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserToken = () => {
        // const userToken = localStorage.getItem('user-token');
        // if (!userToken || userToken === 'undefined') {
        //     setIsLoggedIn(false);
        //     return navigate('/auth/login');
        // }
        // setIsLoggedIn(true);
        const user = JSON.parse(localStorage.getItem('user') + "");
        if (!user || !user?.accessToken) {
            // user is not authenticated
            setIsLoggedIn(false);
            return navigate("/");
        }
        setIsLoggedIn(true);

    }

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

    return (
        <React.Fragment>
            {isLoggedIn ? props.children : null}
        </React.Fragment>

    )
}


export default ProtectedRoute;
