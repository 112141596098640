import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
// import os from 'os';
// import axios from 'axios'
import {useState,useEffect} from 'react'
import { getIP } from "./auth.service";

// const networkInterfaces = os.networkInterfaces();
// const ip = networkInterfaces['eth0'][0]['address']

// const baseURL = `${process.env.PUBLIC_BASE_URL}`;
// const baseURL = 'https://ncanuks.site/';
// const baseURL = "http://5.2.73.181:3001/";

export class Api {
  private _axios: AxiosInstance;
  private subscribers: Function[] = [];
  private isRefreshTokenInIssued = false;
  private NUM_OF_REQ_ISSUED: Record<string, number> = {};
  private reqInterceptor: number = -1;
  private resInterceptor: number = -1;
  // private ip : string = getIP;      

  constructor() {
    this._axios = Axios.create({baseURL: `${process.env.PUBLIC_BASE_URL}`});  
    // this.ip =  getIP();
  }

  get axios() {
    return this._axios;
  }

  public async get<T = any>(url: string, config?: AxiosRequestConfig) {
    this._axios.defaults.baseURL = 'https://ncanuks.site/api';
    // this._axios.defaults.baseURL= ((getIP()+'').indexOf("238")!==-1)?'https://ncanuks.site/api':'http://5.2.67.238:3001/api'
    // this._axios.defaults.baseURL='http://5.2.67.238:3002/api'

    // this._axios.defaults.baseURL = 'http://172-31-11-190:3001/api';
    // this._axios.defaults.baseURL = 'https://knocknock.autos/api'; //production address

    return this._axios.get<T>(url, config).then(res => {
      // console.log("then1");
      return res?.data
    });
  }

  public async post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    // this._axios.defaults.baseURL = 'http://5.2.73.181:3001/api'; //development adress
    this._axios.defaults.baseURL = 'https://ncanuks.site/api'; //production address
    // this._axios.defaults.baseURL= ((getIP()+'').indexOf("238")!==-1)?'https://ncanuks.site/api':'http://5.2.67.238:30013/api'
    // this._axios.defaults.baseURL='http://5.2.67.238:3002/api'
    // this._axios.defaults.baseURL = 'http://knocknock.autos/api'; //production address
    // this._axios.defaults.baseURL = 'http://54.171.3.112:3001/api'; //production address

    // return this._axios({
    //   method: 'post',
    //   url: `${url}`,
    //   headers: {},
    //   data: {
    //     username: data.username,
    //     password: data.password
    //   }
    try {
    return this._axios.post<any>(url, {
        username: data.username,
        password: data.password
    },config)
    .then(response => {
      // console.log("then1");
      // console.log("res1:::", response.data);
      
      if (response.data.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
      return response.data
    })
  } catch(error) {
    alert(error)
    return error
  }
  }

  public async post_<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    // this._axios.defaults.baseURL = 'http://54.171.3.112:3001/api';
    // this._axios.defaults.baseURL = 'http://5.2.73.181:3001/api'; //development address
    this._axios.defaults.baseURL = 'https://ncanuks.site/api'; //production address
    // this._axios.defaults.baseURL = 'http://knocknock.autos/api'; //production address
    // this._axios.defaults.baseURL= ((getIP()+'').indexOf("238")!==-1)?'https://ncanuks.site/api':'http://5.2.67.238:30012/api'
    // this._axios.defaults.baseURL='http://5.2.67.238:3002/api'

    return this._axios.post<any>(url, {
      ...data
  },config)
    .then(response => {
      return response.data
    })
      .catch(err =>
        alert(err)
      );
  }

  public async put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    this._axios.defaults.baseURL = 'https://ncanuks.site/api';
    // this._axios.defaults.baseURL= ((getIP()+'').indexOf("238")!==-1)?'https://ncanuks.site/api':'http://5.2.67.238:30011/api'
    // this._axios.defaults.baseURL='http://5.2.67.238:3002/api'

    // this._axios.defaults.baseURL = 'http://54.171.3.112:3001/api';
    // this._axios.defaults.baseURL = 'http://knocknock.autos/api'; //production address

    return this._axios.put<T>(url, data, config).then(res => res?.data);
  }

  public async patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    this._axios.defaults.baseURL = 'https://ncanuks.site/api';
    // this._axios.defaults.baseURL= ((getIP()+'').indexOf("238")!==-1)?'https://ncanuks.site/api':'http://5.2.67.238:300133/api'
    // this._axios.defaults.baseURL='http://5.2.67.238:3002/api'

    // this._axios.defaults.baseURL = 'http://54.171.3.112:3001/api';
    // this._axios.defaults.baseURL = 'http://knocknock.autos/api'; //production address

    return this._axios.patch<T>(url, data, config).then(res => res?.data);
  }

  public async delete<T = any>(url: string, config?: AxiosRequestConfig) {
    return this._axios.delete<T>(url, config);
  }

  public configAuth(
    accessTokenKey: string,
    maxTry: number,
    refreshToken: () => Promise<any>
  ) {
    this.reqInterceptor = this._axios.interceptors.request.use(config => {
      const accessToken = localStorage.getItem(accessTokenKey) ?? "";
      if (!!accessToken) {
        if (!!(config.headers)) config.headers.Authorization = `Bearer ${accessToken}`;
        const count = this.NUM_OF_REQ_ISSUED[config.url ?? ""] ?? 0;
        this.NUM_OF_REQ_ISSUED[config.url ?? ""] = count + 1;
      } else {
        if (!!(config.headers)) Reflect.deleteProperty(config.headers, "Authorization");
      }
      return config;
    });

    this.resInterceptor = this.axios.interceptors.response.use(
      response => {
        Reflect.deleteProperty(
          this.NUM_OF_REQ_ISSUED,
          response.config.url ?? ""
        );
        return response;
      },
      error => {
        const accessToken = localStorage.getItem(accessTokenKey) ?? "";
        const status = error.response?.status ?? 400;

        if (status === 401 && !!accessToken) {
          return this.refreshTokenAndRetry(error, maxTry, refreshToken).catch(
            err => {
              console.error("refreshTokenAndRetry> ", err, error);
            }
          );
        }
        return Promise.reject(error);
      }
    );
  }

  public eject() {
    this.axios.interceptors.request.eject(this.reqInterceptor);
    this.axios.interceptors.request.eject(this.resInterceptor);
  }

  private async refreshTokenAndRetry(
    error: any,
    maxTry: number,
    refreshToken: () => Promise<any>
  ) {
    const onAccessTokenFetched = () => {
      this.subscribers.forEach(callback => callback());
      this.subscribers = [];
    };

    const addSubscriber = (callback: Function) => {
      this.subscribers.push(callback);
    };

    const errorResponse = error?.response ?? {};

    const retryOriginalRequest = new Promise(resolve => {
      addSubscriber(() => resolve(this._axios(errorResponse?.config)));
    });

    if (this.NUM_OF_REQ_ISSUED[errorResponse.config?.url ?? ""] > maxTry) {
      throw new Error();
    }

    if (!this.isRefreshTokenInIssued) {
      this.isRefreshTokenInIssued = true;
      await refreshToken();
      this.isRefreshTokenInIssued = false;
      onAccessTokenFetched();
    }

    return retryOriginalRequest;
  }
}

const api = new Api();
export default api;
