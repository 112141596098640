import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Switch from "@mui/material/Switch";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

// import { makeStyles } from "@mui/styles";
import { TextField, Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./order222.css";
// import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookform/resolvers/yup';
import { User } from "../../Login/login.types";
import {
  getOrderHistories,
  getTradeHistories,
  getPositions,
  makeOrder,
} from "components/BinanceFuture/Order/order.service";
import LoadingButton from "components/LoadingButton";
// import LoadingButton from '@mui/lab/LoadingButton';
import { useDebouncedCallback } from "use-debounce";
import { PositionType, OrderHType, TradeHType } from "./Order.types";
import { Stack } from "@mui/system";
interface SymbolInfo {
  symbol: string;
  quantityPrecision: Float32Array;
}
type PropType = {
  fixed: boolean;
  fixedLost: string;
  setFixed: React.Dispatch<React.SetStateAction<boolean>>;
  setFixedLost: React.Dispatch<React.SetStateAction<string>>;
  indexProp: number;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  symbol: string;
  setSymbol: React.Dispatch<React.SetStateAction<string>>;
  balance: string;
  setBalance: React.Dispatch<React.SetStateAction<string>>;
  price: string;
  setPrice: React.Dispatch<React.SetStateAction<string>>;
  sl: string;
  setSl: React.Dispatch<React.SetStateAction<string>>;
  tp1: string;
  setTp1: React.Dispatch<React.SetStateAction<string>>;
  tp2: string;
  setTp2: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  lastValue: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPositions: React.Dispatch<React.SetStateAction<PositionType[]>>;
  setOrderHistories: React.Dispatch<React.SetStateAction<OrderHType[]>>;
  setTradeHistories: React.Dispatch<React.SetStateAction<TradeHType[]>>;
};

const Order = (props: PropType) => {
  const {
    setFixed,
    setFixedLost,
    indexProp,
    description,
    setDescription,
    symbol,
    setSymbol,
    loading,
    setLoading,
    fixed,
    fixedLost,
    setPositions,
    setOrderHistories,
    setTradeHistories,
    balance,
    lastValue,
    price,
    setPrice,
    sl,
    setSl,
    tp1,
    setTp1,
    tp2,
    setTp2
    // setBalance
  } = props;
  const location = useLocation();
  const stateSymbol = location.state?.symbolsAll;

  //   const symbolsAll = stateSymbol.map((symbolInfo: SymbolInfo) => symbolInfo.symbol) || [];
  // const symbolInfo = location.state?.symbolsAll?.map((symbolInfo: SymbolInfo) => symbolInfo.symbol).find((symbolInfo:any) => symbolInfo.symbol === symbol);
  // const quantityPrecision = symbolInfo?.quantityPrecision;
  //        console.log("quantityPrecision", quantityPrecision);
  const symbolsAll = stateSymbol.map(
    (symbolInfo: SymbolInfo) => symbolInfo.symbol
  );

  const symbolToFind = symbol;
  const symbolInfo = stateSymbol.find(
    (symbolInfo: SymbolInfo) => symbolInfo.symbol === symbolToFind
  );
  const quantityPrecision = symbolInfo?.quantityPrecision;

  console.log("quantityPrecision", quantityPrecision);

  // const top100Films = [
  //   { label: "BNBUSDT", year: "BNBUSDT" },
  //   { label: "BTCUSDT", year: "BTCUSDT" },
  //   { label: "The Godfather: Part II", year: "1974" },
  //   { label: "The Dark Knight", year: "2008" },
  //   { label: "12 Angry Men", year: "1957" },
  //   { label: "Schindler's List", year: "1993" },
  //   { label: "Pulp Fiction", year: "1994" },
  // ];

  // const [value, setValue] = useState<FilmOptionType | null>(null);

  // const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  //   const classes = useStyles();
  // const [symbol, setSymbol] = useState("");
  const [qty, setQty] = useState("");
  // const [description, setDescription] = useState("");
  const [limit, setLimit] = useState("LIMIT");
  const [leverage, setLeverage] = useState("3");
  const [sltp, setSltp] = useState(false);
  const [half, setHalf] = useState(false);

  useEffect(() => {
    // Calculate fixedLost whenever sl, price, fixedLost, or leverage changes
    fixedLostCalculator();

    // Fetch trading symbols from the Binance API
  }, [sl, price, fixedLost, leverage]);

  // useEffect(()=>{
  //   console.log("half1",half);

  //   if (!half){
  //     console.log("half",half);

  //     setTp2("")
  //   }
  // }, [half])

  const handleSltp = (event: any) => {
    setSltp(event.target.checked);
    if (!sltp) {
      setSl("");
      setTp1("");
      setTp2("");
    }
  };
  const handleHalf = (event: any) => {
    setHalf(event.target.checked);

    if (!half) {
      console.log("half", half);

      setTp2("");
    }
  };
  const handleFixed = (event: any) => {
    setFixed(event.target.checked);
  };

  const {
    // register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<User>({
    mode: "onBlur", // Validation will trigger on the blur and change events.
    // resolver: yupResolver(userSchema),
  });

  // Debounce callback
  const handleChangeNumbers = (
    e: any,
    setState: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const regex = /^[0-9.\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setState(e.target.value);
    }
  };
  const fixedLostCalculator = async () => {
    if (fixed && fixedLost !== "" && sl !== "" && price !== "") {
      let desiredLoss = parseFloat(fixedLost);
      let initialPrice = parseFloat(price);
      let stopLossPrice = parseFloat(sl);
      let lossPerShare = initialPrice - stopLossPrice;
      let numberOfShares = desiredLoss / lossPerShare;
      let calculatedQtyUSDT = Math.abs(
        (numberOfShares * initialPrice) / parseFloat(leverage)
      );

      // 370=x*3*1000
      // =0.123
      // .123=100/(1100-900)

      setQty(calculatedQtyUSDT.toFixed(0).toString()); // Convert back to string if needed
      console.log(
        desiredLoss,
        initialPrice,
        stopLossPrice,
        lossPerShare,
        numberOfShares,
        calculatedQtyUSDT
      );
    }
  };
  const refreshPositionHistory = async () => {
    const positions = await getPositions(description);
    const orderHistories = await getOrderHistories(description);
    const tradeHistories = await getTradeHistories(description);
    setOrderHistories(orderHistories);
    setTradeHistories(tradeHistories);
    setPositions(positions);
  };
  const debounced = useDebouncedCallback(
    refreshPositionHistory,
    5000 // delay in ms
  );

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    // setMessage("")

    // console.log("user data: ", data, "others::: ", price, qty, limit, leverage);
    // check weather buy or sell dependes indexProp
    // if (indexProp === 0) { //buy
    // } else { //sell
    // }

    await makeOrder(
      !!(indexProp === 0),
      symbol,
      limit,
      leverage,
      price,
      qty,
      description,
      sl,
      tp1,
      half ? tp2 : "0",
      lastValue.toString(),
      half,
      quantityPrecision.toString()
    ).then(
      () => {
        //   navigate("/settings");
        //window.location.reload();
        setLoading(false);
        refreshPositionHistory();
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
      }
    );
    // handleClose();
  };

  return (
    <>
      <div>
        <Typography
          variant="subtitle1"
          gutterBottom
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <FormControlLabel
            control={
              <Switch
                checked={fixed}
                onChange={handleFixed}
                name="fixed"
                color="primary"
                inputProps={{ "aria-label": "Toggle Fixed Lost" }}
              />
            }
            label={"Fixed Lost"}
          />
          {`*Balance: $${balance}`}
        </Typography>
      </div>
      <form className="order-root" onSubmit={onSubmit}>
        <Stack direction="row" spacing={"1px"}>
          <TextField
            label="Leverage"
            className="order-leverage"
            variant="filled"
            required
            value={leverage}
            onChange={(e) => setLeverage(e.target.value)}
          />

          <Autocomplete
            value={symbol}
            onChange={(event: any, newValue: string | null) => {
              setSymbol(newValue === null ? "" : newValue);
            }}
            inputValue={symbol}
            onInputChange={(event, newInputValue) => {
              setSymbol(newInputValue);
            }}
            id="controllable-states-demo"
            options={symbolsAll} // Use the fetched symbols here
            sx={{ width: 220 }}
            renderInput={(params) => <TextField {...params} label="Symbol" />}
          />
        </Stack>

        <Stack direction="row" spacing={"1px"} style={{ marginTop: "8px" }}>
          <div className="order-limitBox">
            <FormControl className="order-fcLimit">
              <InputLabel id="demo-simple-select-label">Typei</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={limit}
                className="order-limit"
                label="LIMIT"
                onChange={(e) => {
                  // console.log("Current Value", e.target.value)
                  setLimit(e.target.value);
                }}
              >
                <MenuItem value="LIMIT">Limit</MenuItem>
                <MenuItem value="MARKET">Market</MenuItem>
                <MenuItem value="ADD">Add</MenuItem>
              </Select>
            </FormControl>
            {fixed && (
              <TextField
                label="Fixed Lost"
                className="order-fixed"
                variant="filled"
                // required
                value={fixedLost}
                // onChange={(e) => setFixedLost(e.target.value)}
                onChange={(e: any) => handleChangeNumbers(e, setFixedLost)}
              />
            )}
          </div>
        </Stack>
        {limit === "LIMIT" && (
          <>
            {" "}
            <TextField
              label="Price(USDT)"
              variant="filled"
              required
              value={price}
              onChange={(e: any) => handleChangeNumbers(e, setPrice)}
            />
          </>
        )}

        {limit !== "ADD" && (
          <>
            <TextField
              label="Qty(USDT)"
              variant="filled"
              required
              value={qty}
              onChange={(e: any) => handleChangeNumbers(e, setQty)}
              disabled={fixed}
              // $120 - ($1,000 / 20) = $120 - $50 = $70
              // helperText={
              //   sl !== "" &&
              //   limit === "LIMIT" &&
              //   price !== "" &&
              //   qty !== "" &&
              //   indexProp === 0 &&
              //   parseFloat(price) >= parseFloat(sl) ? (
              //     <span style={{ color: "red", fontSize: 14 }}>
              //       Liquid Price{" "}
              //       <span style={{ fontSize: "22px", verticalAlign: "middle" }}>
              //         ≈{"I don't Know  "}
              //       </span>
              //       {/* {(
              //         parseFloat(price) -
              //         (parseInt(qty) * parseInt(leverage)) / parseInt(leverage)
              //       )

              //         .toFixed(2)
              //         .toString()}
              //       $ */}
              //     </span>
              //   ) : indexProp === 1 && parseFloat(price) <= parseFloat(sl) ? (
              //     <span style={{ color: "red", fontSize: 14 }}>
              //       Liquid Price{" "}
              //       <span style={{ fontSize: "22px", verticalAlign: "middle" }}>
              //         ≈{"I don't Know "}
              //       </span>
              //       {/* {(
              //         parseFloat(price) +
              //         (parseInt(qty) * parseInt(leverage)) / parseInt(leverage)
              //       )

              //         .toFixed(2)
              //         .toString()}
              //       $ */}
              //     </span>
              //   ) : (
              //     ""
              //   )
              // }
            />
          </>
        )}
        <TextField
          label="Description"
          variant="filled"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            debounced();
          }}
          // onChange={(e: any) => debounced(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox checked={sltp} onChange={handleSltp} name="sltp" />
          }
          label="SL/TP"
        />
        {sltp && (
          <div>
            <TextField
              label="StopLoss (USDT)"
              variant="filled"
              InputProps={{ style: { fontSize: 18, color: "red" } }}
              InputLabelProps={{ style: { fontSize: 18, color: "red" } }}
              value={sl}
              helperText={
                sl !== "" &&
                limit === "LIMIT" &&
                price !== "" &&
                qty !== "" &&
                ((indexProp === 0 && parseFloat(price) >= parseFloat(sl)) ||
                  (indexProp === 1 && parseFloat(price) <= parseFloat(sl))) ? (
                  <span style={{ color: "red", fontSize: 14 }}>
                    You'll lose{" "}
                    {(
                      ((parseInt(qty) * parseInt(leverage)) /
                        parseFloat(price)) *
                      Math.abs(parseFloat(price) - parseFloat(sl))
                    )
                      .toFixed(2)
                      .toString()}
                    $
                  </span>
                ) : (
                  ""
                )
              }
              onChange={(e: any) => {
                handleChangeNumbers(e, setSl);
                fixedLostCalculator();
              }}
            />

            <Table style={{ borderCollapse: "collapse", width: "100%" }}>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{ padding: 0, margin: 0, width: "80% !important" }}
                  >
                    {/* First Column, Row 2 */}

                    <TextField
                      label={half ? "TakeProfit 1 (USDT)" : "TakeProfit (USDT)"}
                      variant="filled"
                      value={tp1}
                      InputProps={{
                        style: { width: "100%", fontSize: 14, color: "green" },
                      }}
                      InputLabelProps={{
                        style: { fontSize: 18, color: "green" },
                      }}
                      helperText={
                        tp1 !== "" &&
                        limit === "LIMIT" &&
                        price !== "" &&
                        qty !== "" &&
                        ((indexProp === 0 &&
                          parseFloat(price) <= parseFloat(tp1)) ||
                          (indexProp === 1 &&
                            parseFloat(price) >= parseFloat(tp1))) ? (
                          <span style={{ color: "green", fontSize: 14 }}>
                            You'll make{" "}
                            {(
                              (((parseInt(qty) * parseInt(leverage)) /
                                parseFloat(price)) *
                                Math.abs(parseFloat(price) - parseFloat(tp1))) /
                              (half ? 2 : 1)
                            )
                              .toFixed(2)
                              .toString()}
                            $
                          </span>
                        ) : (
                          ""
                        )
                      }
                      onChange={(e: any) => handleChangeNumbers(e, setTp1)}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      padding: 0,
                      margin: 0,
                      textAlign: "center",
                      width: "10px !important",
                    }}
                  >
                    {half &&
                      (indexProp === 0 && Number(tp1) <= Number(lastValue) ? (
                        <p>TP{" > "}Last Price</p>
                      ) : indexProp === 1 &&
                        Number(tp1) >= Number(lastValue) ? (
                        <p>
                          <p>TP{" < "}Last Price</p>
                        </p>
                      ) : (
                        ""
                      ))}
                    {
                      <Switch
                        checked={half}
                        onChange={handleHalf}
                        color="primary"
                        inputProps={{ "aria-label": "Toggle Half" }}
                      />
                    }
                  </TableCell>
                </TableRow>

                {half && (
                  <TableRow style={{ padding: 0, margin: 0 }}>
                    <TableCell
                      style={{ padding: 0, margin: 0, width: "80% !important" }}
                    >
                      {/* First Column, Row 3 */}
                      <TextField
                        label="TakeProfit 2 (USDT)"
                        variant="filled"
                        value={tp2}
                        InputProps={{ style: { fontSize: 14, color: "green" } }}
                        InputLabelProps={{
                          style: { fontSize: 18, color: "green" },
                        }}
                        helperText={
                          tp2 !== "" &&
                          limit === "LIMIT" &&
                          price !== "" &&
                          qty !== "" &&
                          ((indexProp === 0 &&
                            parseFloat(price) <= parseFloat(tp2)) ||
                            (indexProp === 1 &&
                              parseFloat(price) >= parseFloat(tp2))) ? (
                            <span style={{ color: "green", fontSize: 14 }}>
                              You'll make{" "}
                              {(
                                (((parseInt(qty) * parseInt(leverage)) /
                                  parseFloat(price)) *
                                  Math.abs(
                                    parseFloat(price) - parseFloat(tp2)
                                  )) /
                                2
                              )
                                .toFixed(2)
                                .toString()}
                              $
                            </span>
                          ) : (
                            ""
                          )
                        }
                        onChange={(e: any) => handleChangeNumbers(e, setTp2)}
                      />
                    </TableCell>
                    <TableCell>
                      {/* Second Column, Row 3 */}
                      {/* Empty cell */}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        )}

        <div>
          {/* <Button
                        type="submit"
                        variant="contained"
                        style={{ color: 'white', backgroundColor: `${indexProp === 0 ? 'green' : 'red'}` }}
                        disabled={!isDirty || !isValid}
                    >
                        {indexProp === 0 ? "Buy" : "Sell"}
                    </Button> */}

          <LoadingButton
            type="submit"
            // disabled={!isValid || !isDirty}
            loading={loading}
            className={indexProp === 0 ? "button-buy" : "button-sell"}
            text={indexProp === 0 ? "Buy" : "Sell"}
          />
        </div>
      </form>
    </>
  );
};

export default Order;
