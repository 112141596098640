import React, { useState, ReactNode, useEffect } from "react";

import "./App.css";
import ErrorBoundary from './components/err';

import Login from "./components/Login";

// import Page404 from "components/Page404";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
// import { Signup } from "components/Login2";
import BinanceFuture from "components/BinanceFuture";
import api from "services/api2";
import ProtectedRoute from "ProtectedRoute";
import { getIP } from "services/auth.service";
import KucoinFuture from  "components/BinanceFuture";

interface Props {
  children?: ReactNode;
}

const AppWrapper = () => {
  return (
    <div className="wrapper">
      {/* <ErrorBoundary> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          {/* <Route element={<Page404 />}></Route> */}

          <Route
            path="/BinanceFuture"
            element={
              <ProtectedRoute>
                <BinanceFuture />
              </ProtectedRoute>
            }
          />

          <Route
            path="/KucoinFuture"
            element={
              <ProtectedRoute>
                <KucoinFuture />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
      {/* </ErrorBoundary> */}
    </div>
  );
};

export default AppWrapper;
