// import axios from "axios";
import api2 from "./api2";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useQuery, useMutation, QueryCache } from "react-query";
import { User } from "components/Login";
import { withRouterApi } from "./withRouterApi";
import { resolve } from "dns";

const API_URL = "/auth/signin";

// class AuthService {
export async function login(username: string, password: string, navigate: any) {
  // return axios
  //   .post(API_URL + "signin", {
  //     username,
  //     password
  //   })
  return await api2
    .post(API_URL, {
      username,
      password,
      navigate,
    })
    .then((response) => {
      // console.log("then2");
      return response;
      //   if (response.data.accessToken) {
      //     localStorage.setItem("user", JSON.stringify(response.data));
    });

  //   return response.data;
  // });
}

export function logout() {
  localStorage.removeItem("user");
}

// register(firstname: string, lastname: string, email: string, password: string) {
//   return axios.post(API_URL + "signup", {
//     firstname,
//     lastname,
//     email,
//     password
//   });
// }

export function getCurrentUser() {
  return JSON.parse(localStorage.getItem("user") + "");
}

//creating function to load ip address from the API
export async function getIP() {
  return new Promise(async function (resolve, reject) {

  await axios.get("https://geolocation-db.com/json/").then((res) => {
    // console.log(res.data.IPv4);
    resolve( res.data.IPv4);
  });
  // console.log(res.data);
  // setIP(res.data.IPv4)
})}
// }

// export default new AuthService();
